import { appendCss } from '../utils/commonUtils';
import { publicPath, widgetGlobalName, cdnPath } from './config.json';
import sdkURL, { query, getCustomizeParams } from './sdkHost';
import appendScript from './jsscript';

const widgetCDNURL = `${sdkURL}${publicPath}`;
const sdkExport = { [cdnPath]: `${widgetCDNURL}/` };

const widgetCaller = (() => {
  let widgetReadyCallbackCalled = false;
  let widgetJSReady = false;
  let outerCallback;

  const runner = fn => (params) => {
    fn(params);
    if (!widgetReadyCallbackCalled && widgetJSReady && outerCallback) {
      widgetReadyCallbackCalled = true;
      outerCallback(window[widgetGlobalName]);
    }
  };

  return {
    setWidgetReady: runner(() => {
      widgetJSReady = true;
    }),
    setCallback: runner((callback) => {
      outerCallback = callback;
    }),
  };
})();

sdkExport.CUIWidget = {
  onReady: widgetCaller.setCallback,
};

Object.assign(window, sdkExport);

const params = getCustomizeParams([ 'widgets' ]);

if (query.widgets) {
  query.widgets.split(',').forEach((widget) => {
    appendScript(`${widgetCDNURL}/${widget}Widget.min.js${params}`, widgetCaller.setWidgetReady);
    appendCss(`${widgetCDNURL}/${widget}Widget.css${params}`);
  });
} else {
  appendScript(`${widgetCDNURL}/widgets.min.js${params}`, widgetCaller.setWidgetReady);
  appendCss(`${widgetCDNURL}/widgets.css${params}`);
}
