import URL from 'url-parse';
import find from 'lodash/find';
import { omit } from '../utils/commonUtils';

const scripts = document.getElementsByTagName('script');
const sdkUrlScript = find(scripts, (o) => {
  return (o.src.indexOf('cui') !== -1 || o.src.indexOf('checkout-widget') !== -1 || o.src.indexOf('checkoutwidgets') !== -1 || o.src.indexOf('saleswidget') !== -1) && o.src.indexOf('/checkout/static/widget/sdk.min.js') !== -1;
});
const { query } = new URL(sdkUrlScript.src, true);
const preUrl = sdkUrlScript.src.split('/checkout/')[0];

const getCustomizeParams = (blacklist) => {
  const parameters = Object.keys(omit(query, blacklist))
    .map(key => `${key}=${query[key]}`)
    .join('&');
  return parameters ? `?${parameters}` : '';
};

export { query, getCustomizeParams };
export default `${preUrl}`;
