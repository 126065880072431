const supportedLocales = [
  // from zh_CN
  'zh_CN',
  'zh_SG',

  // from zh_TW
  'zh_HK',
  'zh_TW',

  // from en_US
  'en_US',
  'en_AU',
  'en_CA',
  'en_GB',
  'en_IE',
  'en_NZ',
  'en_SG',
  'en_ZA',

  // from es_ES
  'es_ES',
  'es_MX',
  'es_US',

  'ph_PH',

  // from fr_FR
  'fr_FR',
  'fr_CA',
  'fr_CH',

  // from de_DE
  'de_AT',
  'de_DE',
  'de_CH',

  'it_IT',
  'it_CH',
  'da_DK',
  'no_NO',
  'sv_SE',
  'ja_JP',
  'ko_KR',
  'pt_BR',
  'ms_MY',
  'th_TH',
  'pl_PL',

  // from ca_ES
  'ca_ES',
  'cy_GB',
];

export default (locale) => {
  return !!supportedLocales.includes(locale);
};
