export default {
  allowedSchemes: [ 'http', 'https' ],
  allowedTags: [ 'font', 'a', 'b', 'br', 'i', 'li', 'ol', 'p', 'u', 'ul', 'blockquote', 'strong', 'em', 'img' ],
  allowedAttributes: {
    a: [ 'href', 'target' ],
    p: [ 'align', 'style' ],
    img: [ 'src', 'alt', 'style' ],
    font: [ 'style', 'font-size', 'color', 'letterspacing', 'kerning', 'size', 'face', 'class', 'dir' ],
  },
};
