import React from 'react';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';
import isPlainObject from 'lodash/isPlainObject';
import mapValues from 'lodash/mapValues';
import mergeWith from 'lodash/mergeWith';
import unescape from 'lodash/unescape';
import forEach from 'lodash/forEach';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import sanitizeHtml from 'sanitize-html';
import isSupportedLocales from './data/locales';
import validOverrideClassKeys from '../config/validOverrideClassKeys';
import sanitizeHtmlConfig from '../config/sanitizeHtmlConfig';

const DEFAULT_LOCALE = 'en_US';

/**
 * @description handle keyboard event with specific key/keys
 * @param handler {Function}
 * @param keys { 'Enter' | 'Esc' | ' ' | ['Enter', 'Esc', ' '] | string | string[]}
 * @returns {(function(e: KeyboardEvent ): void)|*}
 */
export const handleKeyboardEvent = (handler, keys, isPreventDefault = true) => (e) => {
  if (!keys || keys.length === 0) return;
  const _keys = Array.isArray(keys) ? keys : [ keys ];
  if (e.key && _keys.includes(e.key)) {
    handler(e);
    isPreventDefault && e.preventDefault();
  }
};

export const loadI18nMsg = (locale, callback) => {
  const loadingLocale = isSupportedLocales(locale) ? locale : DEFAULT_LOCALE;
  const modules = [
    import(`../../i18n/${loadingLocale}.json`),
  ];

  Promise.all(modules).then((messages) => {
    callback(Object.assign({}, ...messages));
  });
};

export const appendNode = (...args) => {
  let tar = document.body;
  let tag;
  let attr;
  if (args.length === 0) {
    return null;
  }
  if (args.length < 3) {
    [ tag, attr = {} ] = args;
  } else {
    [ tar, tag, attr ] = args;
  }

  const dom = document.createElement(tag);
  Object.keys(attr).forEach(key => dom.setAttribute(key, attr[key]));
  return tar.appendChild(dom);
};

export const appendCss = config => appendNode(document.head, 'link', mergeWith({
  type: 'text/css', rel: 'stylesheet',
}, (typeof config === 'string' ? { href: config } : config)));

export const omit = (obj, omitKeys = []) => (
  Object.keys(obj).reduce(
    (acc, key) => (omitKeys.indexOf(key) === -1 ? Object.assign(acc, { [key]: obj[key] }) : acc),
    {},
  )
);

// Cryptograms must be eight or more characters and contain three of these:
// lowercase, uppercase, numeric, and special characters.
const CRYPTOGRAMREG = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,}$/;

export const getByPath = (obj, path) => path.split('.').reduce((acc, k) => (acc ? acc[k] : acc), obj || {});

export const LOCAL_PREFIX = 'checkout.lib.components.';

export const filterNumbers = value => value && value.replace(/\D/g, '');

/**
 * This builder will build the proxy triggers for each of the child
 * convert those data: ['newCard', 'savedCard'], orgionTriggers
 * to: { newCardTriggers: { ...proxyTriggers }, savedCardTriggers: { ...proxyTriggers } }
 */
export const buildChildrenTriggers = (children, triggers, getCurrentChild) => {
  const triggerNames = Object.keys(triggers);
  const triggerMappings = {};

  // Return the proxy triggers
  const buildProxyTrigger = triggerName => (...args) => triggerMappings[
    getCurrentChild()
  ][triggerName].apply(null, args);
  triggerNames.forEach(triggerName => triggers[triggerName](buildProxyTrigger(triggerName)));

  // Build children triggers
  const setTrigger = (trigger, child, triggerName) => {
    // Set the child component trigger to the map, and then the proxy trigger can use it.
    (triggerMappings[child] = triggerMappings[child] || {})[triggerName] = trigger;
  };
  const buildChildTriggers = child => triggerNames.reduce(
    (childTriggers, triggerName) => Object.assign(childTriggers, {
      [triggerName]: (trigger) => {
        setTrigger(trigger, child, triggerName);
      },
    }),
    {},
  );
  return children.reduce(
    (childrenTriggers, child) => Object.assign(childrenTriggers, { [`${child}Triggers`]: buildChildTriggers(child) }),
    {},
  );
};

export const appendScript = (url, onReadyCallback, async, container, onErrorCallback) => {
  // const existScript = Array.from(document.scripts).filter(s => s.src === url)[0];
  // if (existScript) {
  //   onReadyCallback && onReadyCallback();
  //   return;
  // }

  const targetScript = document.createElement('script');
  targetScript.type = 'text/javascript';
  targetScript.async = async || async === undefined;
  targetScript.onload = () => {
    onReadyCallback && onReadyCallback();
  };
  targetScript.onerror = () => {
    onErrorCallback && onErrorCallback('error');
  };

  targetScript.src = url;
  if (container === 'head') {
    document.head.appendChild(targetScript);
  } else {
    document.body.appendChild(targetScript);
  }
};

export const appendScriptPromise = (url) => {
  return new Promise((resove) => {
    appendScript(url, () => {
      resove(true);
    }, false, 'head', () => {
      resove(false);
    });
  });
};

export const interpolate = (...args) => {
  const str = args.shift(); // str
  if (!str) { return null; }
  const context = args.length === 1 ? args[0] : args; // values
  const matched = str.match(/{([\s\S]+?)}/g); // Field to replace in str
  const interpolateMap = matched && matched.map(m => ({ // Field to replace in str : value
    [m]: m.replace(/{|}/g, '').split('.').reduce((acc, k) => (acc && acc[k]), context),
  })).filter(
    entry => !isNil(Object.values(entry)[0]),
  ).reduce(
    (acc, entry) => ({ ...acc, ...entry }), {},
  );

  if (!context || !matched || !interpolateMap || Object.keys(interpolateMap).length === 0) {
    return str;
  }

  const keys = matched.map(k => k.replace(/\{/g, '\\{').replace(/\}/g, '\\}'));
  const values = Object.values(interpolateMap);

  if (!values.some(value => typeof value !== 'string')) {
    return str.replace(new RegExp(keys.join('|'), 'g'), match => (interpolateMap[match] || ''));
  }

  return (
    <React.Fragment>
      {str.split(new RegExp(keys.join('|'))).map((prefix, idx) => (
        <React.Fragment key={idx}>
          {prefix}
          {values[idx]}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export const addURLParam = (url, name, value) => {
  const newParam = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
  return url.indexOf('?') === -1 ? `${url}?${newParam}` : `${url}&${newParam}`;
};

// filter undefined, null and '' from obj
export const objectFilter = (obj) => {
  return omitBy(mapValues(obj, (value) => {
    if (isPlainObject(value)) {
      return objectFilter(value);
    }
    return isNil(value) || value === '' ? undefined : value;
  }), isUndefined);
};

export const isCryptogram = (value) => {
  return CRYPTOGRAMREG.test(value);
};

export const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'TABLET';
  } if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return 'MOBILE';
  }
  return 'DESKTOP';
};

export const readCookie = function (name) {
  const nameEQ = `${name }=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return '';
};

export const readCookieItem = function (cookieName, itemName) {
  let cookieValue = unescape(readCookie(cookieName));

  if (cookieValue.indexOf('"') === 0) { // Version 1 cookie, wrapped in double quote. see https://bugs.eclipse.org/bugs/show_bug.cgi?id=315715 for details
    cookieValue = cookieValue.substring(1, cookieValue.length - 1); // remove double quote
  }

  let resultValue;

  if (cookieValue !== '') {
    const itemIndex = cookieValue.indexOf(`${itemName }=`);
    if (itemIndex > -1) {
      let multiValueSplitIndex = cookieValue.indexOf('&', itemIndex);
      if (multiValueSplitIndex === -1) {
        multiValueSplitIndex = cookieValue.length;
      }
      if (multiValueSplitIndex > -1 && itemIndex < multiValueSplitIndex) {
        resultValue = cookieValue.substring(itemIndex + itemName.length
                  + 1, multiValueSplitIndex);
      }
    }
  }

  return resultValue;
};

export const autoScrollToTheErrorField = (container = document) => {
  const viewWidth = window.innerWidth;
  const viewHeight = window.innerHeight;
  // Page error || form field error
  const errorField = container.querySelector('.ic-cross-r');
  // CC paymentMethod iframe
  const paymentMethodField = container.querySelector('.checkout-ui-component__payment-method')?.querySelector('iframe');

  // If have no page error and form error Scroll to the given field
  const scrollToField = errorField || paymentMethodField;
  if (scrollToField) { // The first error element on the page
    const {
      top,
      right,
      bottom,
      left,
    } = scrollToField.getBoundingClientRect();
    const isInViewPort = top >= 0
  && left >= 0
  && right <= viewWidth
  && bottom <= viewHeight;
    if (!isInViewPort) {
      scrollToField.scrollIntoView({ block: 'center' });
    }
    if (errorField) {
      const pageErrorField = container.querySelector('.alert-inline-danger');
      const formErrorInputField = errorField.parentNode?.parentNode?.querySelector('input');
      const formErrorDropdownField = errorField.parentNode?.parentNode?.querySelector('.dropdown');
      const errorFocusField = pageErrorField || formErrorInputField || formErrorDropdownField;
      errorFocusField.focus();
    } else { // paymentMethodField
      const paymentMethodErrorInputField = paymentMethodField?.contentDocument
        ?.querySelector('.ic-cross-r')?.parentNode?.parentNode?.querySelector('input'); // For wcag use
      const paymentMethodErrorDropdownField = paymentMethodField?.contentDocument
        ?.querySelector('.ic-cross-r')?.parentNode?.parentNode?.querySelector('.dropdown');
      const paymentMethodErrorField = paymentMethodErrorInputField || paymentMethodErrorDropdownField;
      paymentMethodErrorField && paymentMethodErrorField.focus();
    }
  }
};

export const styleOverride = ({ nameSpace, overrideStyles }) => {
  if (!nameSpace) {
    return;
  }

  const styleSheetTitle = `${nameSpace }__overrideStyles`;
  const styleSheets = document.querySelectorAll('style');
  const overrideStyleSheets = filter(styleSheets, (s) => { return s.title === styleSheetTitle; });

  forEach(overrideStyleSheets, (o) => {
    o.parentNode.removeChild(o);
  });

  if (!overrideStyles || isEmpty(overrideStyles)) {
    return;
  }

  let css = '';
  const cssPre = `.checkout_root .checkout_container .checkout__widgets .${ nameSpace } .checkout-ui-component .`;
  forEach(overrideStyles, (v, k) => {
    if (validOverrideClassKeys[k]) {
      let vCss = '{ ';
      forEach(v, (subV, subK) => {
        if (subV) {
          const newSubV = subV.indexOf('!important') !== -1 ? `${subV }; ` : `${subV } !important; `;
          vCss = `${vCss + subK }: ${ newSubV}`;
        }
      });
      vCss += '}';
      const cssItem = `${`${ cssPre + k } ${ vCss }`} `;
      css += cssItem;
    }
  });

  if (css === '') { // All invalid
    return;
  }

  const target = document.createElement('style');
  target.type = 'text/css';
  target.title = styleSheetTitle;
  target.appendChild(document.createTextNode(css));
  document.head.appendChild(target);
};

export const checkHtml = (str) => {
  const reg = /<[^<>]+>/g;
  return reg.test(str);
};

export const cleanHtml = (htmlStr) => {
  return sanitizeHtml(htmlStr, sanitizeHtmlConfig);
};
